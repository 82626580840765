import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { CouponResolver } from './resolvers/coupon/coupon.resolver';
import { InventoryResolver } from './resolvers/inventory/inventory.resolver';
import { SocialResolver } from './resolvers/Social/social.resolver';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'inventory',
    pathMatch: 'full'
  },
  {
    path: 'inventory',
    loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule),
    canActivate: [AuthGuard],
    resolve: {
      resolved: InventoryResolver
    }
  },
  {
    path: 'order',
    loadChildren: () => import('./order/order.module').then(m => m.OrderModule)
  },
  {
    path: 'coupons',
    loadChildren: () => import('./coupon/coupon.module').then(m => m.CouponModule),
    canActivate: [AuthGuard],
    resolve: {
      resolved: CouponResolver
    }
  },
  {
    path: 'social',
    loadChildren: () => import('./social/social.module').then(m => m.SocialModule),
    canActivate: [AuthGuard],
    resolve: {
      resolved: SocialResolver
    }
  },
  {
    path: 'config',
    loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule)
  },
  {
    path: 'auth',
    component: AuthComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
