import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import S3RequestPart from 'src/app/interfaces/common/S3RequestPart';
import Category from 'src/app/interfaces/inventory/Category';
import InventoryItem from 'src/app/interfaces/inventory/InventoryItem';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  apiUrl: string;

  HttpHeaders = new HttpHeaders({
    accept: 'application/json, text/plain, */*',
    'Cache-Control': 'no-cache'
  });

  constructor(
    private http_client: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}/planted`;
    // this.apiUrl = `api/planted`;
  }

  get_all_inventory() {
    return this.http_client.get<InventoryItem[]>(`${this.apiUrl}/inventory`, { headers: this.HttpHeaders });
  }

  update_inventory_item(item: InventoryItem) {
    return this.http_client.put(`${this.apiUrl}/inventory`, [item], { headers: this.HttpHeaders });
  }

  get_all_categories() {
    return this.http_client.get<Category[]>(`${this.apiUrl}/categories`, { headers: this.HttpHeaders });
  }
}
