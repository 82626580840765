import InventoryItem from "src/app/interfaces/inventory/InventoryItem";

class IdsHelper {

    static getInventoryItemsIds(items: InventoryItem[]): string[] {
        const inventory_item_ids = items.map(function (item) {
            return item.inventory_id
        });

        return inventory_item_ids;
    }

    static getVariantIds(items: InventoryItem[]): string[] {
        let variant_ids: string[] = [];
        items.forEach((value, index, array) => {
            variant_ids = [...variant_ids, ...value.variants.map((variant) => {
                return variant.variant_id;
            })];
        });

        return variant_ids;
    }
}

export default IdsHelper;
