import CouponOption from "../interfaces/coupon/CouponOption";

export const CouponEffects: CouponOption[] = [
    {
        key: 'FreeVariant',
        title: 'Free Variant',
        properties: [
            {
                name: 'variant_id',
                title: 'Varant ID',
                type: 'text',
                symbol: ''
            },
            {
                name: 'variant_count',
                title: 'Varant Count',
                type: 'number', 
                symbol: ''

            }
        ]
    },
    {
        key: 'FlatRate',
        title: 'Flat Rate',
        properties: [
            {
                name: 'rate',
                title: 'Rate',
                type: 'number',
                symbol: '$'
            }
        ]
    },
    {
        key: 'PercentOffOrder',
        title: 'Percent Off (Order)',
        properties: [
            {
                name: 'rate',
                title: 'Rate',
                type: 'number',
                symbol: '%'
            }
        ]
    },
    {
        key: 'PercentOffVariant',
        title: 'Percent Off (Variant)',
        properties: [
            {
                name: 'variant_id',
                title: 'Varant ID',
                type: 'text',
                symbol: '%'
            },
            {
                name: 'rate',
                title: 'Rate',
                type: 'number',
                symbol: '$'
            }
        ]
    },
    {
        key: 'FreeDelivery',
        title: 'Free Delivery',
        properties: []
    }
];

export const CouponRequirements: CouponOption[] = [
    {
        key: 'OrderMinimum',
        title: 'Order Minimum',
        properties: [
            {
                name: 'minimum',
                title: 'Minimum',
                type: 'number',
                symbol: '$',
            }
        ]
    },
    {
        key: 'OrderMaximum',
        title: 'Order Maximum',
        properties: [
            {
                name: 'maximum',
                title: 'Maximum',
                type: 'number',
                symbol: '$'
            }
        ]
    },
    {
        key: 'RequiredVariant',
        title: 'Required Variant',
        properties: [
            {
                name: 'variant_id',
                title: 'Varant ID',
                type: 'text',
                symbol: ''
            },
            {
                name: 'required_amount',
                title: 'Required Amount',
                type: 'number',
                symbol: '$'
            }
        ]
    },
    {
        key: 'OrderType',
        title: 'Order Type',
        properties: [
            {
                name: 'order_type',
                title: 'Order Type',
                type: 'text',
                symbol: ''
            },
        ]
    },
    // {
    //     key: 'Zip',
    //     title: 'Zip',
    //     properties: []
    // },
    {
        key: 'BOGO',
        title: 'BOGO',
        properties: [
            {
                name: 'variant_id',
                title: 'Varant ID',
                type: 'text',
                symbol: ''
            },
        ]
    },
    {
        key: 'LIMIT',
        title: 'Limit per customer',
        properties: [
            {
                name: 'limit',
                title: 'Limit',
                type: 'number',
                symbol: ''
            }
        ]
    }
];