import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, from } from 'rxjs';
import { DataService } from 'src/app/services/data/data.service';
import { fetchCoupons, setStateUpdateDate } from 'src/app/store/coupon.state';

@Injectable({
  providedIn: 'root'
})
export class CouponResolver implements Resolve<boolean> {

  constructor(
    private store: Store,
    private dataService: DataService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return from(this.setupCoupons());
  }

  private async setupCoupons() {
    let StateLoadDate = this.store.selectSnapshot<Date>(store => store.CouponState.StateLoadDate);
    const currentDate = new Date;
    if (StateLoadDate != null) {
      const diffMs = (currentDate.getTime() - StateLoadDate.getTime());
      if (Math.round(diffMs / 60000) < 5) {
        return true;
      }
    }
    try {
      await this.dataService.loadCouponState();
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}
